import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Fade } from "react-reveal";

function Apply() {
  return (
    <section className="container relative py-12  md:py-[8rem] ">
      <div className="relative rounded-md px-[24px] md:px-[40px] lg:px-[100px] py-[80px] overflow-hidden">
      <Fade ssrReveal bottom delay={350}>
      {/* Check if the overlay is causing the issue */}
        <div className="absolute top-0 left-0 h-full w-full z-100 rounded-md bg-gradient-to-tr from-hotPink via-purplish to-accent "></div>

        <div className="mx-auto relative z-20">
          <div className="grid grid-cols-1 gap-x-6 gap-y-14 lg:grid-cols-2">
            <div>
              <h4 className="mb-4 text-[30px] md:text-[36px] font-bold text-white leading-tight">
                Subscribe to our crypto news weekly newsletter!
              </h4>
              <p className="text-[16px] text-white lg:pr-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                fermentum nunc, enim nascetur fermentum justo.
              </p>
            </div>
            <div className="flex flex-col md:flex-row justify-end items-center gap-2">
              <input
                placeholder="Enter your email adderss"
                type="email"
                className="border border-white bg-transparent rounded-lg text-white placeholder:text-white py-[22px] md:py-[24px] w-full "
              />
              <button className="min-w-full md:min-w-[200px]  items-center justify-center text-[16px] font-bold md:text-[18px] py-[22px] px-[32px]  button rounded-md bg-white text-jacarta-800 z-[2] transition-all duration-500 hover:-translate-y-1">
                <span className="bg-gradient-to-tr from-hotPink via-purplish to-accent bg-clip-text text-transparent">
                  Subscribe
                </span>
              </button>
              {/* <Link
                href={"/learn"}
                className="items-center justify-center text-[16px] font-bold md:text-[18px] py-[22px] px-[32px] md:py-[24px] md:px-[38px] button rounded-md bg-white text-jacarta-800 z-[2] transition-all duration-500 hover:-translate-y-1"
              >
                <span className="bg-gradient-to-tr from-hotPink via-purplish to-accent bg-clip-text text-transparent">
                  Browse open positions
                </span>
              </Link> */}
            </div>
          </div>
        </div>
        <div className="w-full absolute top-[30%] left-0 z-[10] mix-blend-color-dodge">
          <div className="h-[300px] w-[1200px] animate-fade">
            <Image
              fill
              sizes="100vw"
              src="/images/hero2.webp"
              alt="dots-background"
              className="object-cover"
            />
          </div>
        </div>
        </Fade>
      </div>
    </section>
  );
}

export default Apply;
